<template>
  <div class="d-flex flex-column">
    <div>
      <SequenceEditorRecap :localCurrentSkeleton="localCurrentSkeleton" />
    </div>
    <div v-if="displayDuplicateTracksSection" class="hasduplicate-section">
      <b-row>
        <b-col md="9" class="d-flex align-items-center">
          Des titres sont présents plusieurs fois dans votre playlist,
          souhaitez-vous supprimer les doublons ?
        </b-col>
        <b-col md="3" class="d-flex align-items-center">
          <h-button
            class="ml-auto mr-2"
            grey
            @click="displayDuplicateTracksSection = false"
          >
            Non
          </h-button>
          <h-button blue @click="deleteDuplicateTracks">Oui</h-button>
        </b-col>
      </b-row>
    </div>
    <div
      class="track-list mt-2"
      :class="{ 'duplicates-openned': displayDuplicateTracksSection }"
    >
      <SequenceEditorTitles :localCurrentSkeleton="localCurrentSkeleton" />
    </div>
  </div>
</template>

<script>
import SequenceEditorRecap from '@/components/musique/SequenceEditorRecap'
import SequenceEditorTitles from '@/components/musique/SequenceEditorTitles'
export default {
  components: {
    SequenceEditorRecap,
    SequenceEditorTitles,
  },
  data() {
    return {
      localCurrentSkeleton: null,
      displayDuplicateTracksSection: false,
    }
  },
  methods: {
    deleteDuplicateTracks() {
      this.$store.dispatch('musique/_deleteDuplucates')
    },
  },
  mounted() {
    this.localCurrentSkeleton = JSON.parse(JSON.stringify(this.currentSkeleton))
  },
  computed: {
    duplicateTracks() {
      return this.$store.state.musique.duplicateTracks
    },
    currentSkeleton() {
      return this.$store.state.musique.currentSkeleton
    },
  },
  watch: {
    currentSkeleton: {
      handler() {
        this.localCurrentSkeleton = JSON.parse(
          JSON.stringify(this.currentSkeleton)
        )
      },
      deep: true,
    },
    duplicateTracks() {
      if (this.duplicateTracks && this.duplicateTracks.length > 0)
        this.displayDuplicateTracksSection = true
      else this.displayDuplicateTracksSection = false
    },
  },
}
</script>

<style lang="scss" scoped>
.track-list {
  height: calc(100vh - 272px);
  overflow-y: auto;
  transition: 0.3s;
  &.duplicates-openned {
    height: calc(100vh - 354px);
  }
}
.hasduplicate-section {
  border-radius: 7px;
  border: 1px solid #f0f3f6;
  padding: 10px;
  margin-top: 10px;
}
</style>
