<template>
  <div class="multi-select-container" :class="{ openned: open }">
    <b-icon class="left-icon" :icon="leftIcon"></b-icon>
    <multiselect
      v-model="dataValue"
      @open="open = true"
      @input="changeValue"
      @close="open = false"
      :options="options"
      :multiple="true"
      searchable
      :showLabels="false"
      label="name"
      track-by="id"
      :placeholder="placeholder"
    >
      <span slot="noResult">Aucun résultat</span>
      <span slot="caret" class="caret">
        <b-icon
          :class="{ rotate: open, out: !open }"
          icon="chevron-up"
          scale="1.12"
        ></b-icon
      ></span>
      <template v-if="dataValue.length > 1" slot="tag">
        <span class="multiselect__tag">
          <b>{{ dataValue.length }}</b> tags sélectionnés
          <i
            aria-hidden="true"
            tabindex="1"
            class="multiselect__tag-icon"
            @click="removeAllTags()"
          ></i>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  created() {
    if (this.selectedTags) this.dataValue = this.selectedTags
  },
  data() {
    return {
      open: false,
      dataValue: [],
    }
  },
  props: {
    value: Array,
    options: Array,
    placeholder: String,
    leftIcon: String,
    selectedTags: Array,
  },
  methods: {
    changeValue() {
      let toPush = []
      this.dataValue.forEach((elem) => {
        toPush.push(elem.id)
      })
      this.$emit('input', toPush)
    },
    removeAllTags() {
      this.dataValue = []
      this.changeValue()
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.left-icon {
  transition: 0.2s;
  position: absolute;
  left: 13px;
  top: 33%;
  color: #b0b8c9;
}

.rotate {
  transition: 0.1s ease-out;
}

.out {
  transition: 0.1s ease-out;
  transform: rotate(180deg);
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder {
  color: $h-input-placeholder !important;
  opacity: 1; /* Firefox */
  font-size: 1rem !important;
}
/* Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: $h-input-placeholder !important;
}
</style>
