<template>
  <b-modal
    v-model="modalShow"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    size="lg"
  >
    <div class="px-2">
      <div class="d-block text-center">
        <h4>
          Partage de playlist
        </h4>
      </div>
      <template v-if="!shareCheckLoading">
        <div class="mb-5">
          <b-row>
            <b-col>
              <b-form-checkbox
                @change="switchShareSkeleton"
                v-model="sharePermalink"
                :disabled="loading"
                name="check-button"
                switch
              >
                <span class="no-wrap">
                  <b>{{ sharePermalink ? 'Partagée' : 'Non partagée' }}</b>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="
                permalinks &&
                  permalinks.length > 0 &&
                  !loading &&
                  sharePermalink
              "
              class="d-flex align-items-center"
            >
              <b-button
                class="copy-btn"
                v-b-tooltip.focus="'Copié !'"
                size="sm"
                @click="copyToClipboard(shareUrl(permalinks[0].shortCode))"
                variant="outline"
                ><b-icon icon="files"></b-icon
              ></b-button>
              <a :href="shareUrl(permalinks[0].shortCode)" target="_blank">{{
                shareUrl(permalinks[0].shortCode)
              }}</a>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center justify-content-center">
          <b-spinner class="my-5"></b-spinner>
        </div>
      </template>
      <div class="mt-1 mb-3 d-flex flex-column">
        <div class="d-flex justify-content-center">
          <h-button @click="close" outline blue class="px-3">
            Fermer
          </h-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import musiqueService from '@/services/musiqueService'
export default {
  props: {
    showSharePermalink: Boolean,
    skeletonId: {
      type: Number,
      required: true,
    },
    skeletonName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      shareCheckLoading: false,
      permalinks: null,
      limit: this.tracksNumber,
      sharePermalink: false,
      loading: false,
    }
  },
  methods: {
    async getSkeletonPermalinks() {
      const resPermalinks = await musiqueService.getSkeletonPermalinks(
        this.skeletonId
      )
      if (resPermalinks.data && resPermalinks.data.length > 0)
        this.sharePermalink = true
      this.permalinks = resPermalinks.data
    },
    switchShareSkeleton() {
      if (this.sharePermalink) this.shareSkeleton()
      else this.removeSkeletonPermalink(this.permalinks[0].id)
    },
    async shareSkeleton() {
      this.loading = true
      await musiqueService.createSkeletonPermalink(this.skeletonId, -1)
      this.getSkeletonPermalinks()
      this.loading = false
    },
    async removeSkeletonPermalink(id) {
      this.loading = true
      try {
        await musiqueService.removeSkeletonPermalink(id)
        this.getSkeletonPermalinks()
      } catch (e) {
        this.$toast.error(
          'Une erreur est survenue lors de la suppression de votre lien de partage, veuillez réessayer ultérieurement'
        )
      } finally {
        this.loading = false
      }
    },
    copyToClipboard(shareUrl) {
      navigator.clipboard.writeText(shareUrl)
    },
    shareUrl(shortCode) {
      return `${
        this.env === 'production'
          ? window.location.origin
          : 'http://localhost:5000'
      }/public/share/${shortCode}`
    },
    close() {
      this.$emit('close')
    },
  },
  async created() {
    this.shareCheckLoading = true
    this.getSkeletonPermalinks()

    this.shareCheckLoading = false
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
    currentUserId() {
      return this.$store.state.user.user.id
    },
  },
}
</script>

<style lang="scss" scoped>
.copy-btn:focus {
  box-shadow: none;
}
.no-wrap {
  white-space: nowrap;
}
</style>
