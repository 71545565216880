<template>
  <b-modal
    v-model="modalShow"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="px-2">
      <div class="d-block text-center">
        <h4>
          Confirmer la suppression
        </h4>
      </div>
      <template v-if="!deleteCheckLoading">
        <div class="my-5">
          <p v-if="canDelete" class="lead large text-center">
            Voulez-vous vraiment supprimer la playlist
            <b>{{ skeletonName }}</b> ?
          </p>
          <p v-else class="lead large text-center">
            Vous ne pouvez pas supprimer cette playlist car elle est déjà
            planifiée dans votre planning.
          </p>
        </div>
        <div v-if="canDelete" class="mt-1 mb-3 d-flex flex-column">
          <div class="d-flex justify-content-center">
            <h-button @click="close" outline red class="px-3 mr-3">
              {{ $t('actions.no') }}
            </h-button>
            <h-button @click="deleteSkeleton" green class="px-3 mr-3">
              {{ $t('actions.yes') }}
            </h-button>
          </div>
        </div>
        <div v-else class="mt-1 mb-3 d-flex flex-column">
          <div class="d-flex justify-content-center">
            <h-button @click="close" outline blue class="px-3">
              Fermer
            </h-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center justify-content-center">
          <b-spinner class="my-5"></b-spinner>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import musiqueService from '@/services/musiqueService'
export default {
  props: {
    showDeleteConfirm: Boolean,
    skeletonName: {
      type: String,
      required: true,
    },
    skeletonId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      changeFluxLoading: false,
      deleteInfos: null,
      deleteCheckLoading: false,
    }
  },
  methods: {
    async deleteSkeleton() {
      try {
        await musiqueService.deleteSkeleton(this.skeletonId)
        this.$emit('close')
        this.$store.dispatch('musique/_getSkeletons')
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite sur la suppression de la playlist, veuillez réessayer ultérieurement"
        )
        this.$emit('close')
      }
    },
    close() {
      this.$emit('close')
    },
  },
  async created() {
    this.deleteCheckLoading = true
    const deleteInfo = await musiqueService.deleteSkeletonCheck(this.skeletonId)
    this.deleteInfos = deleteInfo.data
    this.deleteCheckLoading = false
  },
  computed: {
    canDelete() {
      return this.deleteInfos === '[OK_NOT_USED_AT_ALL]'
    },
  },
}
</script>

<style lang="scss" scoped></style>
