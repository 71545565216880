<template>
  <div
    @dragenter="dragenter"
    @dragover="dragenter"
    @drop="dropEvent"
    class="import-spotify mt-1 relative"
  >
    <div
      class="import-content d-flex flex-column justify-content-center align-items-center d-flex"
    >
      <img
        src="@/assets/img/playlist-import.png"
        class="mb-3"
        alt="image playlist"
        width="50px"
      />
      <div v-if="loadingDrop">
        <h6 class="mb-1 mt-0 font-weight-semi-bold text-center">
          {{ $t('planification.importPlaylistRunning') }}
        </h6>
        <span></span>
      </div>
      <div v-else>
        <h6 class="my-0 font-weight-semi-bold text-center">
          {{ $t('planification.dragdropPlaylist') }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import musiqueService from '@/services/musiqueService'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      loadingDrop: false,
    }
  },
  methods: {
    dragenter(evt) {
      evt.stopPropagation()
      evt.preventDefault()
    },
    async dropEvent(evt) {
      this.createPlaylist()
      this.loadingDrop = true
      evt.stopPropagation()
      evt.preventDefault()
      const spotifyUrl = evt.dataTransfer.getData('Text')
      const spotifyPlaylistId = spotifyUrl.split('/').pop()

      if (spotifyUrl && spotifyPlaylistId) {
        try {
          this.$store.commit('musique/SET_FETCH_PLAYLIST_EDIT', true)
          this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', true)
          //Get trackList
          const res = await musiqueService.getSpotifyTracklist(
            spotifyPlaylistId
          )
          this.localTracksList = JSON.parse(res.data)

          const playlistInfo = await musiqueService.getSpotifyPlaylistInfo(
            spotifyPlaylistId
          )

          if (!this.currentSkeleton.coverDataUri) {
            const parsedPlaylistInfos = JSON.parse(playlistInfo.data)
            this.$store.commit(
              'musique/SET_CURRENT_SKELETON_NAME',
              parsedPlaylistInfos.Name
            )
            this.$store.commit(
              'musique/SET_CURRENT_SKELETON_COVER',
              parsedPlaylistInfos.Ref
            )
          }

          let tracksToPush = this.localTracksList.map((obj) => {
            let rObj = {}
            rObj.idSkeletonLine = -1
            rObj.idSkeleton = -1 //Create/Edit
            rObj.skeletonLinetype = 2
            rObj.lineNumber = null
            rObj.title = obj.Title
            rObj.artists = obj.Artists.map((i) => i.Name).join(', ')
            rObj.albumCover = obj.Album.ImageUrl
            rObj.popularity = obj.Popularity
            rObj.isrc = obj.Isrc
            rObj.foundStatus = 'loading'
            return rObj
          })

          //Push tracks to currentSkeleton
          this.$store.dispatch(
            'musique/_editCurrentSkeletonLines',
            tracksToPush
          )

          //Get radioshop tracks by ISRC
          const isrcList = this.localTracksList.map((obj) => obj.Isrc).join(',')
          const params = {
            Name: isrcList,
          }
          let isrcPool = await musiqueService.getByIsrcPool(params)
          isrcPool.data.forEach((isrcMatch) => {
            this.$store.dispatch(
              'musique/_itemCurrentSkeletonItemMatch',
              isrcMatch
            )
          })

          //get radioshop tracks by NamePool
          this.getByNamePool()

          this.$store.commit('musique/SET_FETCH_PLAYLIST_EDIT', false)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('spotify processing error')
          this.$store.commit('musique/SET_FETCH_PLAYLIST_EDIT', false)
          this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', false)
        } finally {
          this.loadingDrop = false
        }
      }
    },
    createPlaylist() {
      const skeleton = {
        idSkeleton: -1,
        idBrand: this.idBrand,
        name: this.$t('planification.newPlaylist'),
        skeletonType: 20,
        visibleInPlayList: true,
        flags: 10,
        idIdentity: this.idIdentity,
        skeletonLine: [],
        idImageCover: -1,
        idMusicLibrary: 1,
        visibility: 2,
      }
      this.$store.dispatch('musique/_createEditCurrentSkeleton', skeleton)
    },
    async getByNamePool() {
      let namePoolListList = []
      let currentSkeletonLinesForNamePool = this.$store.state.musique
        .currentSkeleton.skeletonLine
      for (let line of currentSkeletonLinesForNamePool) {
        if (line.foundStatus === 'loading') {
          const lineTitle =
            line.artists.split(',').join(' &') + ' - ' + line.title
          namePoolListList.push(lineTitle)
        }
      }
      const namePoolListListFormat = namePoolListList.join('|,')
      const params = {
        Name: namePoolListListFormat,
      }
      let namePool = await musiqueService.getByNamePool(params)
      namePool.data.forEach((namePoolMatch) => {
        this.$store.dispatch(
          'musique/_itemCurrentSkeletonItemMatch',
          namePoolMatch
        )
      })
      this.$store.dispatch('musique/_removeNotFoundFromCurrentSkeleton')
    },
  },
  computed: {
    ...mapState({
      idBrand: (state) => state.user.user.visibleBrands[0].id,
      idIdentity: (state) => state.user.user.visibleIdentities[0].id,
      currentSkeleton: (state) => state.musique.currentSkeleton,
    }),
  },
}
</script>

<style lang="scss" scoped>
.import-spotify {
  border: 2px dashed $h-main-text;
  border-radius: 8px;
  width: 100%;
  max-width: 200px;
  padding-top: 93%;
  span {
    color: #6b6b6b;
  }
  .import-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
