<template>
  <div>
    <b-row v-if="fetchingPlaylistState" class="mt-3 mx-0">
      <b-col cols="12" class="px-0">
        <SequenceEditorTitlesItemSkeleton v-for="index in 30" :key="index" />
      </b-col>
    </b-row>

    <b-row
      v-if="!fetchingPlaylistState && localCurrentSkeleton"
      class="mt-3 mx-0"
    >
      <b-col
        cols="12"
        class="px-0"
        @dragenter="dragenter"
        @dragover="dragenter"
        @drop="dropEvent"
      >
        <template v-if="localCurrentSkeleton.skeletonLine.length > 0">
          <draggable v-model="localCurrentSkeleton.skeletonLine">
            <SequenceEditorTitlesItem
              v-for="(songLine, index) in localCurrentSkeleton.skeletonLine"
              :songItem="songLine"
              :key="index"
            />
          </draggable>
        </template>
        <template v-else>
          <b-row
            class="h-100 mx-0 d-flex flex-column justify-content-center align-items-center"
          >
            <b-col cols="12" class="px-0">
              <h5 class="mt-3 mb-4 text-center">
                {{ $t('planification.emptyPlaylist') }}
              </h5>
              <div
                class="import-spotify px-4 mt-1 py-5 d-flex justify-content-center align-items-center
        relative"
              >
                <img
                  src="@/assets/img/playlist-import.png"
                  class="mr-5 ml-2"
                  alt="image playlist"
                  width="75px"
                />
                <div v-if="loadingDrop">
                  <h5 class="mb-1 mt-0 font-weight-semi-bold">
                    {{ $t('planification.importPlaylistRunning') }}
                  </h5>
                </div>
                <div v-else>
                  <h5 class="mb-2 mt-0 font-weight-semi-bold">
                    {{ $t('planification.dragdropPlaylist') }}
                  </h5>
                  <span>
                    {{ $t('planification.dragdropMessage') }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SequenceEditorTitlesItem from '@/components/musique/SequenceEditorTitlesItem'
import SequenceEditorTitlesItemSkeleton from '@/components/skeleton/SequenceEditorTitlesItemSkeleton'
import draggable from 'vuedraggable'

import musiqueService from '@/services/musiqueService'

import { mapState } from 'vuex'
export default {
  components: {
    SequenceEditorTitlesItem,
    SequenceEditorTitlesItemSkeleton,
    draggable,
  },
  props: {
    localCurrentSkeleton: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      currentTracks: (state) => state.musique.currentSkeleton.skeletonLine,
      currentSkeleton: (state) => state.musique.currentSkeleton,
      fetchingPlaylistState: (state) => state.musique.fetchingPlaylistState,
    }),
  },
  data() {
    return {
      loadingDrop: false,
      displayDuplicateTracksSection: false,
    }
  },
  methods: {
    dragenter(evt) {
      evt.stopPropagation()
      evt.preventDefault()
    },
    async dropEvent(evt) {
      if (evt.dataTransfer.effectAllowed === 'move') return
      this.loadingDrop = true
      evt.stopPropagation()
      evt.preventDefault()
      const spotifyUrl = evt.dataTransfer.getData('Text')
      const spotifyPlaylistId = spotifyUrl.split('/').pop()

      if (spotifyUrl && spotifyPlaylistId) {
        try {
          //Get trackList
          const res = await musiqueService.getSpotifyTracklist(
            spotifyPlaylistId
          )
          if (res.data) this.localTracksList = JSON.parse(res.data)
          else this.localTracksList = []

          const playlistInfo = await musiqueService.getSpotifyPlaylistInfo(
            spotifyPlaylistId
          )

          if (!this.currentSkeleton.coverDataUri) {
            const parsedPlaylistInfos = JSON.parse(playlistInfo.data)
            this.$store.commit(
              'musique/SET_CURRENT_SKELETON_NAME',
              parsedPlaylistInfos.Name
            )
            this.$store.commit(
              'musique/SET_CURRENT_SKELETON_COVER',
              parsedPlaylistInfos.Ref
            )
          }

          let tracksToPush = this.localTracksList.map((obj) => {
            let rObj = {}
            rObj.idSkeletonLine = -1
            rObj.idSkeleton = -1 //Create/Edit
            rObj.skeletonLinetype = 2
            rObj.lineNumber = null
            rObj.title = obj.Title
            rObj.artists = obj.Artists.map((i) => i.Name).join(', ')
            rObj.albumCover = obj.Album.ImageUrl
            rObj.popularity = obj.Popularity
            rObj.isrc = obj.Isrc
            rObj.foundStatus = 'loading'
            return rObj
          })

          //Push tracks to currentSkeleton
          this.$store.dispatch(
            'musique/_editCurrentSkeletonLines',
            tracksToPush
          )
          //Get radioshop tracks by ISRC
          const isrcList = this.localTracksList.map((obj) => obj.Isrc).join(',')
          const params = {
            Name: isrcList,
          }
          let isrcPool = await musiqueService.getByIsrcPool(params)
          isrcPool.data.forEach((isrcMatch) => {
            this.$store.dispatch(
              'musique/_itemCurrentSkeletonItemMatch',
              isrcMatch
            )
          })

          //get radioshop tracks by NamePool
          this.getByNamePool()
          this.$store.dispatch('musique/_checkDuplicates')
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('spotify processing error')
        } finally {
          this.loadingDrop = false
        }
      }
    },
    async getByNamePool() {
      let namePoolListList = []
      let currentSkeletonLinesForNamePool = this.$store.state.musique
        .currentSkeleton.skeletonLine
      for (let line of currentSkeletonLinesForNamePool) {
        if (line.foundStatus === 'loading') {
          const lineTitle =
            line.artists.split(',').join(' &') + ' - ' + line.title
          namePoolListList.push(lineTitle)
        }
      }
      const namePoolListListFormat = namePoolListList.join('|,')
      const params = {
        Name: namePoolListListFormat,
      }
      let namePool = await musiqueService.getByNamePool(params)
      namePool.data.forEach((namePoolMatch) => {
        this.$store.dispatch(
          'musique/_itemCurrentSkeletonItemMatch',
          namePoolMatch
        )
      })
      this.$store.dispatch('musique/_removeNotFoundFromCurrentSkeleton')
    },
  },
}
</script>

<style lang="scss" scoped>
.import-spotify {
  border: 2px dashed $h-main-text;
  border-radius: 8px;
  span {
    color: #6b6b6b;
  }
}
</style>
