<template>
  <div class="d-flex title-line align-items-center pr-3">
    <div class="draggable  mr-2 d-flex align-items-center cursor-pointer">
      <img
        class="mx-auto"
        src="@/assets/img/drag-points.svg"
        alt="drag points"
      />
    </div>
    <b-skeleton-img width="40px" height="40px"></b-skeleton-img>
    <div class="mx-3 d-flex flex-grow-1">
      <b-skeleton width="200px"></b-skeleton> <span class="mx-2">-</span>
      <b-skeleton width="120px"></b-skeleton>
    </div>
    <div class="d-flex align-items-center ml-auto">
      <b-skeleton width="110px"></b-skeleton>
    </div>
    <div class="ml-4 mr-2 trash">
      <b-skeleton-img class="ml-2" width="16px" height="16px"></b-skeleton-img>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.title-line {
  height: 40px;
  margin-bottom: 7px;
  cursor: pointer;
}
.draggable {
  border-radius: 2px;
  background: #f7f9fa;
  width: 17px;
  height: 40px;
}
</style>
