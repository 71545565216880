<template>
  <div class="py-2 period">
    <b-row v-if="addPeriod" class="d-flex align-items-center mt-1 mr-0">
      <b-col cols="4" class="d-flex align-items-center pr-0">
        <b-icon icon="calendar2-plus-fill" class="mr-2"></b-icon>
        <span class="label">{{ $t('planification.period') }}</span>
      </b-col>
      <b-col cols="7" class="pr-0">
        <v-date-picker
          :locale="$i18n.locale"
          v-if="range.start !== '1'"
          is-required
          :min-date="new Date()"
          v-model="range"
          :popover="{
            visibility: 'click',
          }"
          is-range
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input
                :value="
                  inputValue.start
                    ? inputValue.start + ' - ' + inputValue.end
                    : $t('planification.choosePeriod')
                "
                v-on="inputEvents.start"
                class="w-100 range-input border px-2 py-1"
              />
            </div>
          </template>
        </v-date-picker>
      </b-col>
      <b-col cols="1" class="cursor-pointer" @click="addPeriod = false">
        <b-icon scale="0.*" icon="trash-fill" class="text-red"></b-icon>
      </b-col>
    </b-row>
    <b-row v-else class="d-flex align-items-center mt-1 mr-0">
      <b-col cols="12">
        <p class="mb-1">
          {{ $t('planification.noLimiteMessage') }}
        </p>
      </b-col>
      <b-col cols="12">
        <h-button @click="addPeriod = true" outline grey small class="my-2">
          <b-icon icon="plus" class="mr-2"></b-icon
          >{{ $t('planification.definePeriod') }}
        </h-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    startDate: {
      type: [Date, String],
    },
    endDate: {
      type: [Date, String],
    },
  },
  data() {
    return {
      addPeriod: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
    }
  },
  created() {
    this.$nextTick(function() {
      if (
        this.$moment(this.endDate).format('YYYY') == '0001' ||
        this.$moment(this.endDate).format('YYYY') == '2060' ||
        this.$moment(this.endDate).format('YYYY') == '2099'
      ) {
        this.addPeriod = false
        this.range.start = null
        this.range.end = null
      } else {
        this.addPeriod = true
        this.range.start = this.startDate
        this.range.end = this.endDate
      }
    })
  },
  watch: {
    addPeriod(val) {
      if (!val) this.$emit('changePeriodRange', { range: { end: null } })
    },
    range: {
      handler(val) {
        this.$emit('changePeriodRange', val)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 15px;
}
.period,
.range-input {
  color: #646e84;
}
.text-red {
  color: #f65e7c;
}
</style>
