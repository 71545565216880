var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 period"},[(_vm.addPeriod)?_c('b-row',{staticClass:"d-flex align-items-center mt-1 mr-0"},[_c('b-col',{staticClass:"d-flex align-items-center pr-0",attrs:{"cols":"4"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"calendar2-plus-fill"}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('planification.period')))])],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"7"}},[(_vm.range.start !== '1')?_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"is-required":"","min-date":new Date(),"popover":{
          visibility: 'click',
        },"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var inputValue = ref.inputValue;
        var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"w-100 range-input border px-2 py-1",domProps:{"value":inputValue.start
                  ? inputValue.start + ' - ' + inputValue.end
                  : _vm.$t('planification.choosePeriod')}},inputEvents.start))])]}}],null,false,2095503734),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}):_vm._e()],1),_c('b-col',{staticClass:"cursor-pointer",attrs:{"cols":"1"},on:{"click":function($event){_vm.addPeriod = false}}},[_c('b-icon',{staticClass:"text-red",attrs:{"scale":"0.*","icon":"trash-fill"}})],1)],1):_c('b-row',{staticClass:"d-flex align-items-center mt-1 mr-0"},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('planification.noLimiteMessage'))+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('h-button',{staticClass:"my-2",attrs:{"outline":"","grey":"","small":""},on:{"click":function($event){_vm.addPeriod = true}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(_vm._s(_vm.$t('planification.definePeriod'))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }