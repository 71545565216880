<template>
  <div>
    <div v-if="fetchingPlaylistState" class="sequence-recap skeleton"></div>
    <div
      v-if="!fetchingPlaylistState && localCurrentSkeleton"
      class="sequence-recap d-flex align-items-center justify-content-between"
    >
      <div>
        <div class="py-1 pr-3 w-100">
          <div class="mb-3">
            <h-button @click="backToPlanif" size="sm" flat blue p0>
              <b-icon icon="chevron-left"></b-icon>
              {{ $t('planification.returnToPlanification') }}
            </h-button>
          </div>
          <div class="d-flex align-items-center ">
            <img
              :src="
                localCurrentSkeleton.coverDataUri
                  ? localCurrentSkeleton.coverDataUri
                  : require('@/assets/img/default-sequence.png')
              "
              class="mr-3 ml-1 playlist-img"
              alt="image playlist"
              @error="imageLoadError"
            />
            <div class="d-flex flex-column">
              <h4 class="font-weight-semi-bold my-0 d-flex align-items-center">
                <h-input
                  v-if="editMode"
                  :valueProp="playlistName"
                  v-model="playlistName"
                  @keyup.enter="editMode = !editMode"
                ></h-input>
                <span v-else>{{ playlistName }}</span>
                <b-icon
                  :icon="editMode ? 'check' : 'pencil-fill'"
                  class="cursor-pointer px-2 icon"
                  :font-scale="editMode ? 1.9 : 1.3"
                  @click="editMode = !editMode"
                ></b-icon>
                <div
                  v-if="localCurrentSkeleton.isTracklist"
                  class="cursor-pointer"
                  @click.stop="shareSkeleton"
                >
                  <b-icon icon="share-fill" scale="0.8"></b-icon>
                </div>
              </h4>
              <span class="titles">
                {{
                  `${localCurrentSkeleton.skeletonLine.length} ${$t(
                    'planification.title'
                  )}${localCurrentSkeleton.skeletonLine.length > 0 ? 's' : ''}`
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-titles flex-grow-1 px-4 mx-2">
        <SequenceEditorAddTitles />
      </div>
      <div class="recap-actions pr-2 d-flex">
        <div class="mx-3">
          <h-button red outline @click="cancelDeletePlaylist">
            {{ isCreateMode ? $t('actions.cancel') : $t('actions.delete') }}
          </h-button>
        </div>
        <h-button @click="createEditPlaylist" green :disabled="loadingCreate">
          <template v-if="loadingCreate">
            <b-spinner small class="mb-1 mr-2"></b-spinner>
            {{ $t('login.loading') }}
          </template>
          <template v-else>{{ $t('actions.save') }}</template>
        </h-button>
      </div>
    </div>
    <ModalConfirmSkeletonDelete
      v-if="localCurrentSkeleton && showDeleteConfirm"
      :showDeleteConfirm="showDeleteConfirm"
      :skeletonName="localCurrentSkeleton.name"
      :skeletonId="parseInt(localCurrentSkeleton.idSkeleton)"
      @close="showDeleteConfirm = false"
    />
    <ModalSkeletonPermalink
      v-if="localCurrentSkeleton && showSharePermalink"
      :showSharePermalink="showSharePermalink"
      :skeletonName="localCurrentSkeleton.name"
      :skeletonId="parseInt(localCurrentSkeleton.idSkeleton)"
      @close="showSharePermalink = false"
    />
  </div>
</template>

<script>
import SequenceEditorAddTitles from '@/components/musique/SequenceEditorAddTitles'
import ModalConfirmSkeletonDelete from '@/components/modals/ModalConfirmSkeletonDelete'
import musiqueService from '@/services/musiqueService'
import { EventBus } from '@/components/base/event-bus.js'
import ModalSkeletonPermalink from '@/components/modals/ModalSkeletonPermalink'
export default {
  components: {
    SequenceEditorAddTitles,
    ModalConfirmSkeletonDelete,
    ModalSkeletonPermalink,
  },
  props: {
    localCurrentSkeleton: {
      type: Object,
    },
  },
  data() {
    return {
      editMode: false,
      localName: '',
      loadingCreate: false,
      showDeleteConfirm: false,
      showSharePermalink: false,
    }
  },
  methods: {
    imageLoadError() {
      const img = require('@/assets/img/default-sequence.png')
      event.target.src = img
    },
    backToPlanif() {
      this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', false)
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    async cancelDeletePlaylist() {
      if (this.isCreateMode) {
        this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', false)
        this.$store.dispatch('musique/_createEditCurrentSkeleton', null)
      } else {
        this.showDeleteConfirm = true
      }
    },
    async createEditPlaylist() {
      this.loadingCreate = true
      const imgBase64 = await this.getBase64FromUrl(
        this.localCurrentSkeleton.coverDataUri
      )
      if (imgBase64.includes('html')) {
        this.$store.commit('musique/SET_CURRENT_SKELETON_COVER', null)
        this.localCurrentSkeleton.coverDataUri = null
      } else {
        this.$store.commit('musique/SET_CURRENT_SKELETON_COVER', imgBase64)
        this.localCurrentSkeleton.coverDataUri = imgBase64
      }

      //re-order all track lines
      let trackLineOrder = 0
      let currentSkeletonForReOrder = JSON.parse(
        JSON.stringify(this.localCurrentSkeleton)
      )
      currentSkeletonForReOrder.skeletonLine.forEach((e) => {
        e.lineNumber = trackLineOrder
        e.skeletonLinetype = 2
        trackLineOrder++
      })
      this.$store.commit(
        'musique/SET_CURRENT_SKELETON',
        currentSkeletonForReOrder
      )
      this.sendSkeleton()
    },
    async sendSkeleton() {
      const IdSite = this.$store.state.user.selectedSite.id
      try {
        const params = this.$store.state.musique.currentSkeleton
        await musiqueService.createEditSkeleton(params)
        await musiqueService.generatePlaylist(IdSite)
        EventBus.$emit(
          'playlistCreateEditSuccess',
          this.$store.state.musique.currentSkeleton.idSkeleton
        )
        this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', false)
      } catch (e) {
        this.$toast.error(
          "Une erreur s'est produite dans la création de votre playlist, veuillez réessayer ultérieurement"
        )
      } finally {
        this.loadingCreate = false
      }
    },
    shareSkeleton() {
      this.showSharePermalink = true
    },
  },
  computed: {
    isCreateMode() {
      if (this.$store.state.musique.currentSkeleton)
        return this.$store.state.musique.currentSkeleton.idSkeleton < 0
      else return false
    },
    playlistName: {
      get() {
        return this.localCurrentSkeleton.name
      },
      set(value) {
        this.$store.dispatch('musique/_editCurrentSkeletonName', value)
      },
    },
    fetchingPlaylistState() {
      return this.$store.state.musique.fetchingPlaylistState
    },
  },
}
</script>

<style lang="scss" scoped>
.back-btn {
  padding: 0;
}
.sequence-recap {
  background: #f0f3f6;
  border-radius: 7px;
  padding: 10px;
  &.skeleton {
    height: 133px;
  }
}
.titles {
  color: #6b6b6b;
}
.playlist-img {
  width: 65px;
  border-radius: 3px;
}
.add-titles {
  border-right: 1px solid #d8dde2;
  border-left: 1px solid #d8dde2;
}
</style>
