<template>
  <div class="current-planning px-3 py-3">
    <b-row class="align-items-center">
      <b-col cols="4" v-if="event.imgSkeleton && event.imgSkeleton > 0">
        <img
          :src="
            (env === 'production' ? '/api' : 'http://localhost:5000/api') +
              `/skeleton/${event.imgSkeleton}/cover`
          "
          alt="image cover"
          class="img w-100"
        />
      </b-col>
      <b-col cols="8" v-if="event.title">
        <div class="title">{{ event.title }}</div>
        <div
          class="d-flex align-items-center mt-2"
          v-for="(e, index) in startTimesComp"
          :key="index"
        >
          <h-input
            v-model="startTimesComp[index]"
            :valueProp="startTimesComp[index]"
            size="sm"
            type="time"
            @input="updateTimes"
          ></h-input>
          <span class="mx-3">à</span>
          <h-input
            v-model="endTimesComp[index]"
            :valueProp="endTimesComp[index]"
            size="sm"
            type="time"
            @input="updateTimes"
          ></h-input>
        </div>
      </b-col>
    </b-row>
    <div class="mt-3 error-time-message" v-if="showTimeError">
      L'heure de fin de votre planification ne peut pas être inférieur à l'heure
      de début
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTimeError: false,
    }
  },
  props: {
    event: {
      type: Object,
    },
  },
  computed: {
    startTimesComp() {
      return this.event.startTimes
    },
    endTimesComp() {
      return this.event.endTimes
    },
    env() {
      return process.env.NODE_ENV
    },
  },
  methods: {
    updateTimes() {
      const times = {
        startTimes: this.startTimesComp,
        endTimes: this.endTimesComp,
      }
      if (
        times.endTimes.every(
          (value, index) => value !== '00:00' && value < times.startTimes[index]
        )
      ) {
        this.showTimeError = true
        this.$emit('formError', true)
      } else {
        this.showTimeError = false
        this.$emit('timeChanged', times)
        this.$emit('formError', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.current-planning {
  background: #e2e8f0;
  border-radius: 10px;
  img {
    border-radius: 10px;
  }
  .title {
    color: $h-main-text-light;
    font-weight: 600;
    font-size: 1.3rem;
  }
  .chevron {
    width: 15px;
    color: $h-main-text;
  }
  .error-time-message {
    color: $h-red;
  }
}
</style>
