<template>
  <div class="px-4 py-3 mb-3 border-top">
    <transition name="scale" mode="out-in">
      <div
        v-if="displayConfirm"
        :key="1"
        class="mt-4 d-flex align-items-center"
      >
        <h-button
          red
          :disabled="deleteLoading"
          class="mx-auto no-white-space d-flex align-items-center"
          @click="deletePlanning"
        >
          <template v-if="deleteLoading">
            <b-spinner small class="mb-1 mr-2"></b-spinner>
            {{ $t('planification.loading') }}
          </template>
          <template v-else>
            <span>{{ $t('planification.confirmDelete') }}</span>
          </template>
        </h-button>
        <h-button
          v-if="!deleteLoading"
          grey
          outline
          class="mx-auto no-white-space d-flex align-items-center"
          @click="displayConfirm = false"
        >
          <span>{{ $t('actions.cancel') }}</span>
        </h-button>
      </div>
      <div v-else :key="2">
        <h-button
          red
          outline
          :disabled="deleteLoading"
          class="mx-auto no-white-space d-flex mt-4 align-items-center"
          @click="deleteElem"
        >
          <span
            >{{ editMode ? $t('actions.delete') : $t('actions.cancel') }}
            {{ $t('planification.thePlanification') }}
          </span>
        </h-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/components/base/event-bus.js'
import musiqueService from '@/services/musiqueService'
export default {
  props: {
    scheduleId: {
      type: [String, Number],
      require: true,
    },
    eventDisplayed: {
      type: Object,
      require: true,
    },
    editMode: {
      type: Boolean,
      require: true,
    },
    selectedSite: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      deleteLoading: false,
      displayConfirm: false,
    }
  },
  methods: {
    deleteElem() {
      if (this.editMode) this.displayConfirm = true
      else this.deletePlanning()
    },
    async deletePlanning() {
      this.deleteLoading = true
      const elem = this.eventDisplayed
      if (this.editMode) {
        try {
          await musiqueService.deleteSchedule(this.scheduleId, [
            this.selectedSite,
          ])
          this.$store.dispatch('musique/_openCloseSideBar', false)
          this.$store.dispatch('musique/_openPlanning', null)
          EventBus.$emit('fetchSchedules')
        } catch (err) {
          if (err.response.status !== 401) {
            this.$toast.error(
              "Une erreur s'est produite, veuillez réessayer ultérieurement"
            )
          }
        } finally {
          this.deleteLoading = false
        }
      } else {
        elem.remove()
        this.deleteLoading = false
        this.$store.dispatch('musique/_openCloseSideBar', false)
        this.$store.dispatch('musique/_openPlanning', null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s;
}
.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.7);
}
.border-top {
  border-top: 1px solid #e4e3e3;
}
</style>
